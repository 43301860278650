import { typeCheckConfig } from 'bootstrap/util/index';
import EventHandler from 'bootstrap/dom/event-handler';
import Manipulator from 'bootstrap/dom/manipulator';
import BaseComponent from 'bootstrap/base-component';

import request from '../vendor/ky';

import Message from './message';
import Modal from './modal';

/***
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const NAME = 'validation';
const DATA_KEY = `app.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;

const DefaultType = {
  evenement: 'number',
  validateUrl: 'string',
};

const Default = {
  evenement: null,
  validateUrl: '',
};

const EVENT_VALIDATE = `validate${EVENT_KEY}`;
const EVENT_VALIDATED = `validated${EVENT_KEY}`;

/***
 * ------------------------------------------------------------------------
 * Methods
 * ------------------------------------------------------------------------
 */

function commentModal(title) {
  const modal = new Modal({
    title,
    content:
      '<textarea class="form-control" name="commentaire" placeholder="Commentaire de refus…" rows="3" required></textarea>',
    headerClose: false,
    buttons: [
      {
        text: 'Annuler',
        value: false,
        attr: {
          'class': 'btn btn-default',
          'data-dismiss': 'modal',
        },
      },
      {
        text: 'Refuser',
        value: true,
        attr: {
          class: 'btn btn-primary',
          type: 'submit',
        },
      },
    ],
  });

  const commentElement = modal._html.body.querySelector('textarea');

  EventHandler.on(modal._html.footer, 'click', '[type="submit"]', (event) => {
    event.preventDefault();

    commentElement.setCustomValidity('');

    if (commentElement.checkValidity()) {
      modal.emit('submit', modal, commentElement.value);
      modal.hide();
    } else {
      commentElement.setCustomValidity('Le commentaire est requis.');
    }
  });

  return modal;
}

/***
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Evenement extends BaseComponent {
  constructor(element, config) {
    super(element);

    this._config = this._getConfig(config);
    this._isValidating = false;

    if (!this._config.validateUrl) {
      throw new Error('Le lien de validation est manquant.');
    }
  }

  // Getters

  get id() {
    return this._config.evenement;
  }

  static get Default() {
    return Default;
  }

  static get DATA_KEY() {
    return DATA_KEY;
  }

  // Public

  validate(allow) {
    const validateEvent = EventHandler.trigger(this._element, EVENT_VALIDATE, {
      id: this.id,
      allow,
    });

    if (this._isValidating || validateEvent.defaultPrevented) {
      return;
    }

    this._isValidating = true;
    this._element.setAttribute('disabled', '');

    if (allow) {
      this._validate(true);
    } else {
      commentModal(`Refuser l'évènement (${this.id})`)
        .once('dismiss', () => {
          this._isValidating = false;
          this._element.removeAttribute('disabled');
        })
        .once('submit', (modal, comment) => {
          this._validate(false, comment);
        })
        .show();
    }
  }

  dispose() {
    super.dispose();
    this._config = null;
  }

  // Private

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...(typeof config === 'object' && config ? config : {}),
    };

    typeCheckConfig(NAME, config, DefaultType);

    return config;
  }

  async _validate(allow, comment) {
    try {
      const data = await request
        .put(this._config.validateUrl, {
          json: { allow, comment },
        })
        .json();

      Message.success(data.detail || `L'évènement (${this.id}) a été validé.`);

      EventHandler.trigger(this._element, EVENT_VALIDATED, {
        id: this.id,
        allow,
      });
    } catch (e) {
      let error;

      try {
        error = await e.response.json().detail;
      } catch {}

      Message.error(
        error ||
          `Impossible de valider l'évènement (${this.id}), une erreur inattendue est survenue.`
      );
    } finally {
      this._isValidating = false;
      this._element.removeAttribute('disabled');
    }
  }
}

export default Evenement;
