import { typeCheckConfig } from 'bootstrap/util/index';
import EventHandler from 'bootstrap/dom/event-handler';
import Manipulator from 'bootstrap/dom/manipulator';
import SelectorEngine from 'bootstrap/dom/selector-engine';
import BaseComponent from 'bootstrap/base-component';

import Modal from './modal';

/***
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'formset';
const DATA_KEY = `app.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const DATA_API_KEY = '.data-api';

const DefaultType = {
  prefix: 'string',
  addButtonLabel: 'string',
  addTitle: 'string',
  deleteButtonLabel: 'string',
  deleteConfirmText: 'string',
  deleteConfirmContent: 'string',
};

const Default = {
  prefix: '',
  addButtonLabel: 'Add another',
  addTitle: 'New item',
  deleteButtonLabel: 'Delete item',
  deleteConfirmText: 'Are you sure to delete this item?',
  deleteConfirmContent:
    'Note that this item will not be deleted until the form submit.',
};

const EVENT_ADDED = `added${EVENT_KEY}`;
const EVENT_DELETE = `delete${EVENT_KEY}`;
const EVENT_CLICK_ADD = `click.add${EVENT_KEY}`;
const EVENT_CLICK_DELETE = `click.delete${EVENT_KEY}`;
const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`;

const CLASS_NAME_EXISTING = 'existing';
const CLASS_NAME_ADD_BUTTON = 'btn btn-secondary mb-3';
const CLASS_NAME_DELETE_BUTTON = 'btn btn-danger btn-sm';

const SELECTOR_ADD_CONTAINER = '.formset-add-container';
const SELECTOR_DELETE_CONTAINER = '.formset-delete-container';
const SELECTOR_FORM = '.formset-form';
const SELECTOR_FORM_TITLE = '.formset-form-title';
const SELECTOR_FORM_DELETE_INPUT = 'input[type=hidden][id$="-DELETE"]';
const SELECTOR_FORM_CHILD_ELEMENT =
  'input,select,textarea,label,div,a,span,img';
const SELECTOR_FORMSET = '.cruditor-formset';

/***
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Formset extends BaseComponent {
  constructor(element, config) {
    super(element);

    this._config = this._getConfig(config);

    if (!this._config.prefix) {
      throw new Error('Missing prefix option for formset.');
    }

    this._addButton = null;

    // Retrieve some of the management form's inputs
    this._totalFormsInput = SelectorEngine.findOne(
      `#id_${this._config.prefix}-TOTAL_FORMS`,
      this._element
    );
    this._maxFormsInput = SelectorEngine.findOne(
      `#id_${this._config.prefix}-MAX_NUM_FORMS`,
      this._element
    );

    // Retrieve form elements and use the last one as the template
    this._forms = this._getForms();
    this._formTemplate = this._buildFormTemplate(
      this._getForms().pop().cloneNode(true)
    );

    // Initialize all the other existing forms
    this._forms.forEach((form) => this._initForm(form));

    this._createAddButton();
  }

  // Getters

  static get Default() {
    return Default;
  }

  static get DATA_KEY() {
    return DATA_KEY;
  }

  // Public

  add() {
    if (!this._canAdd) {
      throw new Error('The maxium number of items is reached.');
    }

    const form = this._formTemplate.cloneNode(true);

    this._initForm(form);

    this._element.insertBefore(
      form,
      this._forms[this._forms.length - 1].nextSibling
    );

    this.update();

    EventHandler.trigger(this._element, EVENT_ADDED, { form });
  }

  delete(form) {
    if (this._config.deleteConfirmText) {
      Modal.confirm(this._config.deleteConfirmText, {
        content: this._config.deleteConfirmContent || false,
      })
        .once('dismiss', (modal, event, button) => {
          if (button && button.value) {
            this._deleteForm(form);
          }
        })
        .show();
    } else {
      this._deleteForm(form);
    }
  }

  update() {
    const forms = this._getForms();

    if (forms.length !== this._forms.length) {
      this._forms = forms;
      this._totalFormsInput.value = this._forms.length;

      this._forms.forEach((form, i) => {
        // Update the form index for its elements
        SelectorEngine.find(SELECTOR_FORM_CHILD_ELEMENT, form).forEach(
          (element) => this._updateFormElementIndex(element, i)
        );
      });
    }

    if (this._addButton) {
      this._addButton.style.display = this._canAdd ? 'inherit' : 'none';
    }
  }

  dispose() {
    EventHandler.off(this._element, EVENT_KEY);

    super.dispose();
    this._config = null;
    this._addButton = null;
    this._totalFormsInput = null;
    this._maxFormsInput = null;
    this._forms = null;
    this._formTemplate = null;
  }

  // Private

  _getConfig(config) {
    config = {
      ...Default,
      ...(typeof config === 'object' && config ? config : {}),
    };

    typeCheckConfig(NAME, config, DefaultType);

    return config;
  }

  get _canAdd() {
    const maxFormsValue = parseInt(this._maxFormsInput.value, 10) || 0;
    const totalFormsValue = this._forms.filter(
      (form) => form.style.display !== 'none'
    ).length;

    return maxFormsValue === 0 || maxFormsValue - totalFormsValue > 0;
  }

  _createAddButton() {
    const container = SelectorEngine.findOne(
      SELECTOR_ADD_CONTAINER,
      this._element
    );
    if (!container) {
      return;
    }

    const button = document.createElement('button');
    button.setAttribute('type', 'button');
    button.className = CLASS_NAME_ADD_BUTTON;
    button.innerText = this._config.addButtonLabel;
    button.style.display = this._canAdd ? 'inherit' : 'none';

    EventHandler.on(button, EVENT_CLICK_ADD, () => this.add());

    container.appendChild(button);

    this._addButton = button;
  }

  _buildFormTemplate(form) {
    const titleElement = SelectorEngine.findOne(SELECTOR_FORM_TITLE, form);
    const deleteElement = SelectorEngine.findOne(
      SELECTOR_FORM_DELETE_INPUT,
      form
    );

    form.removeAttribute('id');
    form.classList.remove(CLASS_NAME_EXISTING);

    if (titleElement) {
      titleElement.innerText = this._config.addTitle;
    }

    if (deleteElement) {
      deleteElement.remove();
    }

    // Reset form inputs' values
    SelectorEngine.find(SELECTOR_FORM_CHILD_ELEMENT, form).forEach(
      (element) => {
        const elementType = element.getAttribute('type');
        if (['checkbox', 'radio'].some((type) => type === elementType)) {
          element.removeAttribute('checked');
        } else {
          element.value = '';
        }
      }
    );

    return form;
  }

  _getForms() {
    return SelectorEngine.find(SELECTOR_FORM, this._element);
  }

  _initForm(element) {
    // Set the title if it is a new form
    if (!element.classList.contains(CLASS_NAME_EXISTING)) {
      const titleElement = SelectorEngine.findOne(SELECTOR_FORM_TITLE, element);

      if (titleElement) {
        titleElement.innerText = this._config.addTitle;
      }
    }

    // Create and add a delete button
    const deleteContainer = SelectorEngine.findOne(
      SELECTOR_DELETE_CONTAINER,
      element
    );
    if (deleteContainer && !SelectorEngine.findOne('button', element)) {
      const deleteButton = document.createElement('button');
      deleteButton.setAttribute('type', 'button');
      deleteButton.className = CLASS_NAME_DELETE_BUTTON;
      deleteButton.innerText = this._config.deleteButtonLabel;

      EventHandler.on(deleteButton, EVENT_CLICK_DELETE, () =>
        this.delete(element)
      );

      deleteContainer.appendChild(deleteButton);
    }
  }

  _updateFormElementIndex(element, newIndex) {
    const idLookup = new RegExp(`${this._config.prefix}-(\\d+|__prefix__)-`);
    const idReplacement = `${this._config.prefix}-${newIndex}-`;

    ['for', 'id', 'name'].forEach((attr) => {
      if (element.hasAttribute(attr)) {
        element.setAttribute(
          attr,
          element.getAttribute(attr).replace(idLookup, idReplacement)
        );
      }
    });
  }

  _deleteForm(element) {
    const deleteEvent = EventHandler.trigger(this._element, EVENT_DELETE, {
      element,
    });

    if (deleteEvent.defaultPrevented) {
      return;
    }

    const deleteInput = SelectorEngine.findOne(
      SELECTOR_FORM_DELETE_INPUT,
      element
    );

    if (deleteInput) {
      deleteInput.value = 'on';
      element.style.display = 'none';
      element.setAttribute('aria-hidden', true);
    } else {
      element.remove();
    }

    this.update();
  }
}

/***
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

EventHandler.on(window, EVENT_LOAD_DATA_API, () => {
  SelectorEngine.find(SELECTOR_FORMSET).forEach(
    (element) => new Formset(element, Manipulator.getDataAttributes(element))
  );
});

export default Formset;
