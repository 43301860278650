// eslint-disable-next-line no-undef,camelcase
__webpack_public_path__ = window.STATIC_URL || '/';

import './vendor/bootstrap';

import './components/formset';

import './views/evenement-form';
import './views/evenement-validation';

import { getSelectorFromElement } from 'bootstrap/util/index';
import EventHandler from 'bootstrap/dom/event-handler';
import Manipulator from 'bootstrap/dom/manipulator';
import SelectorEngine from 'bootstrap/dom/selector-engine';

import Message from './components/message';
import Modal from './components/modal';

/***
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const SELECTOR_DATA_CALENDAR = '[data-bs-calendar]';
const SELECTOR_DATA_TOGGLE_CHECK =
  '[data-bs-toggle="check"], [data-bs-toggle="uncheck"]';
const SELECTOR_DATA_TOGGLE_ENABLE = '[data-bs-toggle="enable"]';
const SELECTOR_DATA_TOGGLE_AJAX_MODAL = '[data-bs-toggle="ajax-modal"]';

/***
 * ------------------------------------------------------------------------
 * Méthodes
 * ------------------------------------------------------------------------
 */

/**
 * Charge dynamiquement les calendriers avec le composant Calendar.
 */
function initCalendars(calendars) {
  if (!calendars.length) {
    return;
  }

  import(/* webpackChunkName: "calendar" */ './components/calendar.js').then(
    ({ default: Calendar }) => {
      calendars.forEach((calendar) => Calendar.calendarInterface(calendar));
    }
  );
}

/***
 * ------------------------------------------------------------------------
 * Évènements
 * ------------------------------------------------------------------------
 */

/**
 * Coche/décoche une ou plusieurs checkbox au clic sur un élément. L'état
 * est défini soit via l'état de l'élément si celui-ci est un champ de
 * type 'checkbox', soit la valeur de son attribut `data-bs-toggle`.
 */
EventHandler.on(
  document,
  'click',
  SELECTOR_DATA_TOGGLE_CHECK,
  function (event) {
    if (event.target.tagName === 'A') {
      event.preventDefault();
    }

    const checked =
      typeof this.checked === 'boolean'
        ? this.checked
        : Manipulator.getDataAttribute(this, 'toggle');
    const selector = getSelectorFromElement(this);

    SelectorEngine.find(selector).forEach((element) => {
      if (element.tagName === 'INPUT' && element.type === 'checkbox') {
        element.checked = checked;
      }
    });
  }
);

/**
 * Active un ou plusieurs éléments cibles lorsqu'un élément (généralement
 * un champ de type 'radio') change. Il est possible de lier des cibles via
 * l'attribut `data-bs-group`, afin qu'une seule ne soit active à la fois.
 */
EventHandler.on(
  document,
  'change',
  SELECTOR_DATA_TOGGLE_ENABLE,
  function (event) {
    if (!event.target.checked) {
      return;
    }

    const selector = getSelectorFromElement(this);

    SelectorEngine.find(selector).forEach((element) => {
      element.removeAttribute('disabled');

      const group = Manipulator.getDataAttribute(element, 'group');

      if (group) {
        SelectorEngine.find(`[data-bs-group="${group}"]`).forEach(
          (groupElement) => {
            if (groupElement !== element) {
              groupElement.setAttribute('disabled', '');
            }
          }
        );
      }
    });
  }
);

/**
 * Charge une page dans une fenêtre modale au clic sur un élément. L'URL de
 * la page est récupérée depuis l'attribut `href` de cet élément.
 */
EventHandler.on(
  document,
  'click',
  SELECTOR_DATA_TOGGLE_AJAX_MODAL,
  function (event) {
    if (this.tagName === 'A') {
      event.preventDefault();
    }

    if (!this.hasAttribute('disabled')) {
      this.setAttribute('disabled', '');

      Modal.fromURL(this.getAttribute('href'))
        .catch((resp) => {
          Message.error(`
          Impossible de charger la page demandée.<br>
          <small class="text-black-50">Erreur : ${resp.statusText} (${resp.req.url})</small>
        `);
        })
        .finally(() => {
          this.removeAttribute('disabled');
        });
    }
  }
);

/***
 * ------------------------------------------------------------------------
 * Initialisation
 * ------------------------------------------------------------------------
 */

window.addEventListener('load', () => {
  // Supprime la classe .no-js une fois la page chargée
  document.documentElement.classList.remove('no-js');

  initCalendars(SelectorEngine.find(SELECTOR_DATA_CALENDAR));
});
