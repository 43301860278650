import { isElement, typeCheckConfig } from 'bootstrap/util/index';
import { DefaultAllowlist, sanitizeHtml } from 'bootstrap/util/sanitizer';
import EventHandler from 'bootstrap/dom/event-handler';
import Manipulator from 'bootstrap/dom/manipulator';
import SelectorEngine from 'bootstrap/dom/selector-engine';
import BaseComponent from 'bootstrap/base-component';

/***
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'message';
const DATA_KEY = `app.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const DATA_API_KEY = '.data-api';

const DefaultType = {
  animation: 'boolean',
  autohide: 'boolean',
  delay: 'number',
};

const Default = {
  animation: true,
  autohide: true,
  delay: 3000,
};

const EVENT_CLICK_DISMISS = `click.dismiss${EVENT_KEY}`;
const EVENT_CLOSE = `close${EVENT_KEY}`;
const EVENT_CLOSED = `closed${EVENT_KEY}`;
const EVENT_MOUSEENTER = `mouseenter${EVENT_KEY}`;
const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`;

const CLASS_NAME_DISMISSIBLE = 'alert-dismissible';
const CLASS_NAME_FADE = 'fade';
const CLASS_NAME_SHOW = 'show';

const SELECTOR_HOLDER = '.messages-holder';
const SELECTOR_MESSAGE = `${SELECTOR_HOLDER} .alert`;
const SELECTOR_DATA_DISMISS = '[data-bs-dismiss="message"]';

/***
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Message extends BaseComponent {
  constructor(element, config) {
    super(element);

    this._config = this._getConfig(config);
    this._timeout = null;

    if (this._config.animation) {
      this._element.classList.add(CLASS_NAME_FADE, CLASS_NAME_SHOW);
    }

    this._setListeners();
  }

  // Getters

  static get Default() {
    return Default;
  }

  static get DATA_KEY() {
    return DATA_KEY;
  }

  // Public

  close() {
    const closeEvent = EventHandler.trigger(this._element, EVENT_CLOSE);

    if (closeEvent.defaultPrevented) {
      return;
    }

    this._element.classList.remove(CLASS_NAME_SHOW);

    this._queueCallback(
      () => this._destroyElement(),
      this._element,
      this._config.animation
    );
  }

  dispose() {
    this._clearTimeout();

    EventHandler.off(this._element, EVENT_CLICK_DISMISS);
    EventHandler.off(this._element, EVENT_MOUSEENTER);

    super.dispose();
    this._config = null;
  }

  // Private

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...(typeof config === 'object' && config ? config : {}),
    };

    typeCheckConfig(NAME, config, DefaultType);

    return config;
  }

  _setListeners() {
    EventHandler.on(
      this._element,
      EVENT_CLICK_DISMISS,
      SELECTOR_DATA_DISMISS,
      () => this.close()
    );

    if (this._config.autohide) {
      this._timeout = setTimeout(() => {
        this.close();
      }, this._config.delay);

      EventHandler.one(this._element, EVENT_MOUSEENTER, () =>
        this._clearTimeout()
      );
    }
  }

  _clearTimeout() {
    clearTimeout(this._timeout);
    this._timeout = null;
  }

  _destroyElement() {
    this._element.remove();
    EventHandler.trigger(this._element, EVENT_CLOSED);
    this.dispose();
  }

  // Static

  static addMessage(level, content, dismissible = true, config = {}) {
    const element = document.createElement('div');
    element.classList.add('alert', `alert-${level}`);
    element.setAttribute('role', 'alert');
    element.setAttribute('aria-live', 'assertive');
    element.setAttribute('aria-atomic', 'true');

    const body = document.createElement('div');
    body.classList.add('alert-body');

    element.appendChild(body);

    if (dismissible) {
      const closeButton = document.createElement('button');
      closeButton.classList.add('btn-close');
      closeButton.setAttribute('aria-label', 'Fermer');
      Manipulator.setDataAttribute(closeButton, 'dismiss', 'message');

      element.appendChild(closeButton);
      element.classList.add(CLASS_NAME_DISMISSIBLE);
    } else if (config.authide !== true) {
      config.autohide = false;
    }

    // Set the message content
    if (typeof content === 'object' && isElement(content)) {
      body.appendChild(content);
    } else {
      body.innerHTML = sanitizeHtml(content, DefaultAllowlist);
    }

    // Append the message and return it
    SelectorEngine.findOne(SELECTOR_HOLDER).appendChild(element);

    return new Message(element, config);
  }

  static success(...args) {
    return Message.addMessage('success', ...args);
  }

  static warning(...args) {
    return Message.addMessage('warning', ...args);
  }

  static error(content, dismissible, config) {
    config = {
      autohide: false,
      ...(typeof config === 'object' && config ? config : {}),
    };

    return Message.addMessage('danger', content, dismissible, config);
  }
}

/***
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

EventHandler.on(window, EVENT_LOAD_DATA_API, () => {
  SelectorEngine.find(SELECTOR_MESSAGE).forEach(
    (message) => new Message(message)
  );
});

export default Message;
