import EventHandler from 'bootstrap/dom/event-handler';
import SelectorEngine from 'bootstrap/dom/selector-engine';

import ModalVanilla from 'modal-vanilla';
import request from '../vendor/ky';

/***
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const NAME = 'modal';
const DATA_KEY = `app.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;

const EVENT_CONTENTCHANGE = `contentchange${EVENT_KEY}`;
const EVENT_CLICK_SUBMIT = `click.submit${EVENT_KEY}`;

const SELECTOR_SUBMIT = '[type="submit"]';

/***
 * ------------------------------------------------------------------------
 * Configuration
 * ------------------------------------------------------------------------
 * voir : https://github.com/KaneCohen/modal-vanilla
 */

ModalVanilla.buttons = {
  confirm: [
    {
      text: 'Annuler',
      value: false,
      attr: {
        'class': 'btn btn-default',
        'data-dismiss': 'modal',
      },
    },
    {
      text: 'OK',
      value: true,
      attr: {
        'class': 'btn btn-primary',
        'data-dismiss': 'modal',
      },
    },
  ],
};

ModalVanilla.templates = {
  container: '<div class="modal" tabindex="-1" role="dialog"></div>',
  dialog: '<div class="modal-dialog" role="document">',
  headerClose:
    '<button type="button" class="btn-close" data-dismiss="modal" aria-label="Fermer"></button>',
};

/***
 * ------------------------------------------------------------------------
 * Classes
 * ------------------------------------------------------------------------
 */

/**
 * Étends les fonctionnalités de modal-vanilla afin de :
 *  - pouvoir charger une URL via `Modal.fromURL()`
 *  - définir la taille de la modale via `options.size` (sm, lg ou xl)
 *  - afficher la modale une fois instanciée via `options.show`
 */
class Modal extends ModalVanilla {
  constructor(options = {}) {
    super(options);

    if (options.size) {
      this._html.dialog.classList.add(`modal-${options.size}`);
    }

    if (options.show) {
      this.show();
    }
  }

  // Private

  _setEvents() {
    super._setEvents();

    EventHandler.on(
      this._html.footer,
      EVENT_CLICK_SUBMIT,
      SELECTOR_SUBMIT,
      (event) => this._handleSubmitForm(event)
    );
  }

  _removeEvents() {
    super._removeEvents();

    EventHandler.off(this._html.footer, EVENT_CLICK_SUBMIT);
  }

  async _handleSubmitForm(event) {
    const form = event.target.hasAttribute('form')
      ? document.getElementById(event.target.getAttribute('form'))
      : SelectorEngine.findOne('form', this._html.body);

    if (!form) {
      return;
    }

    const url =
      event.target.getAttribute('formaction') || form.getAttribute('action');

    if (!url || url === '.') {
      throw new Error('Undefined or invalid form action');
    }

    try {
      const data = await request.post(url, { body: new FormData(form) }).json();

      this.emit('submit', this, data);
      this.hide();
    } catch (e) {
      try {
        const { content } = await e.response.json();

        this._html.body.innerHTML = content;

        EventHandler.trigger(document, EVENT_CONTENTCHANGE, {
          body: this._html.body,
          modal: this,
        });
      } catch {
        throw new Error('Unexpected response while submitting the form');
      }
    }

    return false;
  }

  // Static

  /**
   * Charge une URL et crée un objet Modal avec sa réponse.
   *
   * La réponse retournée par l'URL doit être au format JSON avec les
   * propriétés suivantes :
   *  - title : le titre de la fenêtre modale
   *  - content : le contenu au format HTML
   *
   * En cas de réussite, la fenêtre est directement affichée. Il est
   * possible de modifier ce comportement en définissant `show: false`.
   *
   * Cette méthode retourne un objet Promise auquel on peut attacher deux
   * *callback* via la méthode `then()`, pouvant prendre comme argument :
   *  - (modal) : en cas de réussite
   *  - (response) : en cas d'échec
   *
   * @param {String} url - L'URL à charger
   * @param {Object} [options] - Des options à passer à l'objet Modal
   * @returns {Promise} - L'objet Promise du traitement du chargement
   */
  static async fromURL(url, options = {}) {
    const data = await request.get(url).json();

    const title = data.title || options.title || '';
    const footer = data.footer || options.footer || false;
    const buttons =
      footer && typeof footer !== 'string' ? footer : options.buttons;

    // instancie l'objet avec ses options
    const modal = new Modal({
      ...options,
      title,
      content: data.content,
      header: title !== '',
      footer: buttons ? true : footer,
      buttons: buttons || null,
    });

    EventHandler.trigger(document, EVENT_CONTENTCHANGE, {
      body: modal._html.body,
      modal,
    });

    if (options.show !== false) {
      modal.show();
    }

    return modal;
  }
}

export default Modal;
