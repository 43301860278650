// -----------------------------------------------------------------------------
// Ky
// -----------------------------------------------------------------------------
// see: https://github.com/sindresorhus/ky

import ky from 'ky';

function getCSRFToken() {
  try {
    return document.cookie
      .split('; ')
      .find((row) => row.startsWith('csrftoken='))
      .split('=')[1];
  } catch {
    return '';
  }
}

export default ky.create({
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('X-CSRFToken', getCSRFToken());
      },
    ],
  },
});
